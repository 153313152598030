
export var tableField = [
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '110' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '110' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '' },
  {
    label: '收货数量',
    code: 'quantityReceived',
    type: 'vnode',
    width: '110',
    handle: function (scope) {
      if (scope) {
        return {
          template: `<span>
          <span>${scope.row.quantityReceived}</span>
            <el-tooltip class="item" effect="dark" placement="right">
              <template #content>
              已对账数量:${scope.row.reconciledQuantity}
              <br/>
              未对账数量:${scope.row.uncorrectedQuantity}
              </template>
              <el-button type="text" icon='el-icon-warning-outline'></el-button>
            </el-tooltip>
          </span>`
        }
      }
    }
  },
  {
    label: '暂估含税金额',
    code: 'estimateYesTax',
    type: 'vnode',
    width: '110',
    handle: function (scope) {
      if (scope) {
        return {
          template: `<span>
          <span>${scope.row.estimateYesTax}</span>
            <el-tooltip class="item" effect="dark" placement="right">
              <template #content>
              已对含税金额:${scope.row.stagesReconciliation}
              <br/>
              未对含税金额:${scope.row.stagesNoReconciliation}
              </template>
              <el-button type="text" icon='el-icon-warning-outline'></el-button>
            </el-tooltip>
          </span>`
        }
      }
    }
  },
  {
    label: '本次对账数量',
    code: 'currentReconciliationQuantity',
    width: '110',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        const parentNode = this.$parent.$parent.$parent.$parent
        return {
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          created () {
            parentNode.rowChildren1[scope.$index] = this
          },
          methods: {
            thisblur: function () {
              if (this.rowData.quantityReceived > 0 && parseFloat(this.rowData.currentReconciliationQuantity) < 0) {
                this.$message({
                  message: '收货数量大于零本次对账数量不可为负数！',
                  type: 'error'
                })
                this.$refs.thisinput.focus()
              }
              if (this.rowData.quantityReceived < 0 && parseFloat(this.rowData.currentReconciliationQuantity) > 0) {
                this.$message({
                  message: '收货数量小于零本次对账数量不可为正数！',
                  type: 'error'
                })
                this.$refs.thisinput.focus()
              }
            }
          },
          template: `<span>
          <span v-if="rowData.judgeEdit!='2'">{{rowData.currentReconciliationQuantity}}</span>
          <el-input ref='thisinput' v-if="rowData.judgeEdit=='2'" @blur='thisblur' v-model="rowData.currentReconciliationQuantity" placeholder="请输入数量"></el-input>
          </span>`
        }
      }
    }
  },
  { label: '单价', code: 'unitPrice', type: 'input', width: '' },
  {
    label: '本次含税金额',
    code: 'amountIncludingTax',
    width: '110',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        const parentNode = this.$parent.$parent.$parent.$parent
        return {
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          watch: {
            amountIncludingTax: {
              handler: function () {
                if (this.rowData.judgeEdit === '2') {
                  this.rowData.amountIncludingTax = ''
                }
              },
              deep: true,
              immediate: true
            }
          },
          created () {
            parentNode.rowChildren[scope.$index] = this
          },
          methods: {
            thisblur: function () {
              if (this.rowData.quantityReceived > 0 && parseFloat(this.rowData.amountIncludingTax) < 0) {
                this.$message({
                  message: '收货数量大于零本次含税金额不可为负数！',
                  type: 'error'
                })
                this.$refs.thisinput.focus()
              }
              if (this.rowData.quantityReceived < 0 && parseFloat(this.rowData.amountIncludingTax) > 0) {
                this.$message({
                  message: '收货数量小于零本次本次含税金额不可为正数！',
                  type: 'error'
                })
                this.$refs.thisinput.focus()
              }
            }
          },
          template: `<span>
        <span v-if="rowData.judgeEdit!='2'">{{rowData.amountIncludingTax}}</span>
        <el-input ref='thisinput' v-if="rowData.judgeEdit=='2'" v-model="rowData.amountIncludingTax" @blur='thisblur' placeholder="请输入数量"></el-input>
        </span>`
        }
      }
    }
  },
  // { label: '本次未税金额', code: 'amountNotTaxedThisTime', type: 'input', width: '120' },
  {
    label: '本次未税金额',
    code: 'amountNotTaxedThisTime',
    width: '110',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        // const parentNode = this.$parent.$parent.$parent.$parent
        return {
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          created () {
            // parentNode.rowChildren[scope.$index] = this
          },
          methods: {
            clk: function () {
            }
          },
          template: `<span>
          <span v-if="true">{{rowData.amountNotTaxedThisTime}}</span>
          <el-input v-if="false" v-model="rowData.amountNotTaxedThisTime" placeholder="请输入数量"></el-input>
          </span>`
        }
      }
    }
  },
  { label: '税率', code: 'taxRate', type: 'input', width: '' },
  { label: '本次税额', code: 'currentTaxAmount', type: 'input', width: '' },
  { label: '收货基地', code: 'base', type: 'input', width: '' },
  { label: '内部项目号', code: 'internalProjectNumber', type: 'input', width: '100' },
  { label: '内部项目标识', code: 'internalProjectIdentification', type: 'input', width: '110' },
  {
    label: '物料凭证号',
    code: 'materialVoucherNo',
    type: 'vnode',
    width: '100',
    handle: function (scope) {
      if (scope) {
        return {
          methods: {
            clk: () => {
              this.$emit('diashow', scope)
            }
          },
          template: '<span><el-button size="mini" type="text" @click="clk">' + scope.row.materialVoucherNo + '</el-button></span>'
        }
      }
    }
  },
  { label: '物料凭证行号', code: 'materialVoucherLineNumber', type: 'input', width: '110' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '编辑',
        id: '1',
        stateField: 'judgeEdit',
        stateSymbol: '==',
        stateValue: '1'
      },
      {
        actionLabel: '移除',
        id: '2',
        stateField: 'judgeEdit',
        stateSymbol: '=',
        stateValue: '1'
      },
      {
        actionLabel: '保存',
        id: '3',
        stateField: 'judgeEdit',
        stateSymbol: '!=',
        stateValue: '1'
      },
      {
        actionLabel: '取消',
        id: '4',
        stateField: 'judgeEdit',
        stateSymbol: '!=',
        stateValue: '1'
      }
    ]
  }

]

export var tableData = []

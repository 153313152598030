<template>
  <div class="statementDetail" >
    <!-- 对账明细 -->
    <el-form ref="form">
      <el-form-item >
        <div class="detailTable">
          <global-table @diashow="diaShow" ref="table" max-height="350px" :tableField="tableField" :tableData="form.addDetailList" @tableAction="tableAction"></global-table>
        </div>
      </el-form-item>
    </el-form>
    <dialog-box  ref="addDialog" dialogWidth="50%" :addData="formData.data" :dialogShow="dialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd" title="添加明细" componentName="StatementDetaildialog"
        :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
    </dialog-box>
    <dialog-box  ref="dialogbox" dialogWidth="750px" :showData="showData" :dialogShow="mvNOdialogShow" @handleClose='handleClose'  title="物料凭证" componentName="materialVoucherNodialog"
         :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]">
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { tableField, tableData } from './js/statementDetail'
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'StatementDetail',
  props: ['formData'],
  components: Component.components,
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      dialogShow: false,
      mvNOdialogShow: false,
      showData: {},
      isType: '',
      rowChildren: [],
      rowChildren1: [],
      form: this.formData.data,
      number: '',
      numberTwo: '',
      id: '',
      price: ''
    }
  },
  created () {
    this.form.addDetailList = []
  },
  watch: {
    'form.addDetailList': {
      handler: function () {
        if (this.form.addDetailList) {
          this.form.noTaxInvoiceAmount = 0
          this.form.invoiceAmountIncludingTax = 0
          this.form.invoiceAmount = 0
          this.form.addDetailList.forEach(item => {
            this.form.noTaxInvoiceAmount = (this.form.noTaxInvoiceAmount + (parseFloat(item.amountNotTaxedThisTime) || 0))// .toFixed(2) // 未税
            this.form.invoiceAmountIncludingTax = (this.form.invoiceAmountIncludingTax + (parseFloat(item.amountIncludingTax) || 0))// .toFixed(2) // 含税
            this.form.invoiceAmount = (this.form.invoiceAmount + (parseFloat(item.currentTaxAmount) || 0))// .toFixed(2) // 税额
          })
          this.form.noTaxInvoiceAmount = this.form.noTaxInvoiceAmount.toFixed(2)
          this.form.invoiceAmountIncludingTax = this.form.invoiceAmountIncludingTax.toFixed(2)
          this.form.invoiceAmount = this.form.invoiceAmount.toFixed(2)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleClose () {
      this.dialogShow = false
      this.mvNOdialogShow = false
    },
    confirmAdd () { // 添加明细弹框的确认按钮
      this.form.noTaxInvoiceAmount = 0
      this.form.invoiceAmountIncludingTax = 0
      this.form.invoiceAmount = 0

      var newListData = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
      if (newListData.length > 0) {
        this.form.addDetailList = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
        this.form.addDetailList.forEach(item => {
          if (this.formData.data.statementType === '1') {
            item.judgeEdit = '1'
          } else {
            item.judgeEdit = 1
            item.currentReconciliationQuantity = item.quantityReceived
          }
          this.number = item.materialVoucherNo
          this.id = item.id
          this.numberTwo = item.currentReconciliationQuantity
          this.price = item.amountNotTaxedThisTime
          this.form.noTaxInvoiceAmount = (this.form.noTaxInvoiceAmount + (parseFloat(item.amountNotTaxedThisTime) || 0))// .toFixed(2) // 未税
          this.form.invoiceAmountIncludingTax = (this.form.invoiceAmountIncludingTax + (parseFloat(item.amountIncludingTax) || 0))// .toFixed(2) // 含税
          this.form.invoiceAmount = (this.form.invoiceAmount + (parseFloat(item.currentTaxAmount) || 0))// .toFixed(2) // 税额
        })
        this.form.noTaxInvoiceAmount = this.form.noTaxInvoiceAmount.toFixed(2)
        this.form.invoiceAmountIncludingTax = this.form.invoiceAmountIncludingTax.toFixed(2)
        this.form.invoiceAmount = this.form.invoiceAmount.toFixed(2)
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的明细',
          type: 'warning'
        })
      }
    },
    diaShow (scope) { // 物料凭证号的弹窗
      var number = scope.row.materialVoucherNo
      requestForm('/api/reconciliation/materialDocument/queryVoucherNo?number=' + number, 'post', '').then((res) => {
        if (res.code === '200') {
          this.showData = res.data
          this.mvNOdialogShow = true
        }
      })
    },
    tableAction: function (index, data, action) {
      if (action.id === '1' || action.id === '2') {
        data.judgeEdit = '2'
        if (action.id === '2') {
          this.$confirm('确认移除？', '移除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.form.addDetailList.splice(index, 1) // id为2移除一行
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        }
      } else {
        if (action.id === '3') {
          this.form.addDetailList[index].currentReconciliationQuantity = this.rowChildren1[index].rowData.currentReconciliationQuantity
          this.form.addDetailList[index].amountIncludingTax = this.rowChildren[index].rowData.amountIncludingTax
          const hse = parseFloat(this.rowChildren[index].rowData.amountIncludingTax)
          const sl = parseFloat(this.rowChildren[index].rowData.taxRate)
          this.form.addDetailList[index].amountNotTaxedThisTime = (hse / (1 + sl)).toFixed(2)
          this.form.addDetailList[index].currentTaxAmount = (hse - (hse / (1 + sl))).toFixed(2)
        }
        data.judgeEdit = '1'
      }
    }
  }
}
</script>

<style scoped lang='scss'>
  @import '@/assets/css/elForm.scss';
  .statementDetail{
     .detailTable{
      .tableList {
    padding: 0px;
    margin: 20px 0px;
       }
  }
  }
.mainform{
.form {
.content{
  max-width: 100%;
  margin: 0 auto;
}
}

}
</style>
